/*
| Developed by Dirupt
| Filename : common.contract.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

/*
|--------------------------------------------------------------------------
| Pagination
|--------------------------------------------------------------------------
*/
export type Pagination = {
	total: number
	perPage: number
	currentPage: number
	lastPage: number
	firstPage: number
	firstPageUrl: string
	lastPageUrl: string
	nextPageUrl: string
	previousPageUrl: string
}
export type PaginatedResponse<T> = {
	data: Array<T>
	meta: Pagination
}

/*
|--------------------------------------------------------------------------
| Error codes
|--------------------------------------------------------------------------
*/
export const ErrorCodes = {
	// Common
	// ----------------------------------------------------------------------------
	E_INTERNAL_SERVER_ERROR: 'E_INTERNAL_SERVER_ERROR',
	E_BAD_REQUEST: 'E_BAD_REQUEST',
	E_CONFLICT: 'E_CONFLICT',
	E_RATE_LIMIT: 'E_RATE_LIMIT',
	E_ENTITY_NOT_FOUND: 'E_ENTITY_NOT_FOUND',
	E_VALIDATION: 'E_VALIDATION',
	E_INVALID_INPUT: 'E_INVALID_INPUT',
	E_UNAUTHORIZED: 'E_UNAUTHORIZED',
	E_FORBIDDEN: 'E_FORBIDDEN',
	E_INVALID_TOKEN: 'E_INVALID_TOKEN',
	E_NOT_OWNER: 'E_NOT_OWNER',

	// User
	// ----------------------------------------------------------------------------
	E_USER_NOT_ALLOWED: 'E_USER_NOT_ALLOWED',
	E_USER_BLACKLISTED: 'E_USER_BLACKLISTED',
	E_USER_SUPPORT_ACCESS_DENIED: 'E_USER_SUPPORT_ACCESS_DENIED',
	E_EMAIL_UNVERIFIED: 'E_EMAIL_UNVERIFIED',

	// Redeem
	// ----------------------------------------------------------------------------
	E_REDEEM_CODE_ALREADY_REDEEMED: 'E_REDEEM_CODE_ALREADY_REDEEMED',
	E_REDEEM_CODE_ALREADY_USED: 'E_REDEEM_CODE_ALREADY_USED',
	E_REDEEM_CODE_INVALID: 'E_REDEEM_CODE_INVALID',
	E_REDEEM_CODE_CREATE_FOR_SELF: 'E_REDEEM_CODE_CREATE_FOR_SELF',
	E_REDEEM_CODE_ALREADY_CREATED: 'E_REDEEM_CODE_ALREADY_CREATED',
	E_REDEEM_CODE_ASSOCIATED_TO_OTHER_USER: 'E_REDEEM_CODE_ASSOCIATED_TO_OTHER_USER',
	E_REDEEM_CODE_AMBASSADOR_ONLY: 'E_REDEEM_CODE_AMBASSADOR_ONLY',
	E_REDEEM_CODE_CREATE: 'E_REDEEM_CODE_CREATE',
	E_REDEEM_USER_ALREADY_REDEEMED: 'E_REDEEM_USER_ALREADY_REDEEMED',

	// Posts
	// ----------------------------------------------------------------------------
	E_POST_NOT_FOUND: 'E_POST_NOT_FOUND',
	E_POST_DELETED: 'E_POST_DELETED',

	// Messages
	// ----------------------------------------------------------------------------
	E_MESSAGE_NEED_TO_BE_ACCEPTED: 'E_MESSAGE_NEED_TO_BE_ACCEPTED',

	// Questions
	// ----------------------------------------------------------------------------
	E_QUESTION_QUOTA_REACHED: 'E_QUESTION_QUOTA_REACHED',
	E_QUESTION_ALREADY_ANSWERED: 'E_QUESTION_ALREADY_ANSWERED',
	E_QUESTION_ALREADY_EXISTS: 'E_QUESTION_ALREADY_EXISTS',

	// Plans
	// ----------------------------------------------------------------------------
	E_FREEMIUM_PROFILE_QUOTA_REACHED: 'E_FREEMIUM_PROFILE_QUOTA_REACHED',

	// Event
	// ----------------------------------------------------------------------------
	E_EVENT_IS_NOT_ACTIVE: 'E_EVENT_IS_NOT_ACTIVE',
	E_USER_CANNOT_CREATE_EVENTS: 'E_USER_CANNOT_CREATE_EVENTS',
	E_EVENT_ALREADY_CANCELLED: 'E_EVENT_ALREADY_CANCELLED',
	E_EVENT_CANNOT_CONFIRM_PARTICIPANT: 'E_EVENT_CANNOT_CONFIRM_PARTICIPANT',
	E_EVENT_CANNOT_REFUSE_PARTICIPANT: 'E_EVENT_CANNOT_REFUSE_PARTICIPANT',
	E_EVENT_CANNOT_CANCEL_PARTICIPATION: 'E_EVENT_CANNOT_CANCEL_PARTICIPATION',
	E_EVENT_CANNOT_CANCEL_PARTICIPATION_LESS_THAN_5_DAYS: 'E_EVENT_CANNOT_CANCEL_PARTICIPATION_LESS_THAN_5_DAYS',

	// Partners
	// ----------------------------------------------------------------------------
	E_FREEMIUM_PARTNER_QUOTA_REACHED: 'E_FREEMIUM_PARTNER_QUOTA_REACHED',
	E_USER_IS_NOT_A_PARTNER: 'E_USER_IS_NOT_A_PARTNER',
	E_PARTNER_IS_NOT_ACTIVE: 'E_PARTNER_IS_NOT_ACTIVE',

	// EMerchantPay
	// ----------------------------------------------------------------------------
	E_EMERCHANTPAY_ERROR: 'E_EMERCHANTPAY_ERROR',

	// Stripe
	// ----------------------------------------------------------------------------
	// User already has a plan
	E_STRIPE_ALREADY_FOREVER_MEMBER: 'E_STRIPE_ALREADY_FOREVER_MEMBER',

	// Something went wrong, and we couldn't create a payment link for you.
	E_STRIPE_CHECKOUT_SESSION_FAILED: 'E_STRIPE_CHECKOUT_SESSION_FAILED',

	// Your payment is still pending, please wait a moment and try refreshing your browser.
	E_STRIPE_CHECKOUT_SESSION_PENDING: 'E_STRIPE_CHECKOUT_SESSION_PENDING',

	// There was a mis-match between the expected user and logged in user. Please try refreshing your browser to get your updated plan.
	E_STRIPE_CHECKOUT_SESSION_MISMATCH: 'E_STRIPE_CHECKOUT_SESSION_MISMATCH',

	// Your checkout session could not be retrieved. Please wait a moment and try refreshing your browser.
	E_STRIPE_CHECKOUT_SESSION_NOT_RETRIEVED: 'E_STRIPE_CHECKOUT_SESSION_NOT_RETRIEVED',
} as const
