/*
| Developed by Dirupt
| Filename : SensklePage.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { NextPage } from 'next';
import { BareFetcher, PublicConfiguration } from 'swr/_internal';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type LayoutTypes = 'default' | 'empty' | 'auth' | 'settings';
export interface SensklePageSwrConfig extends Partial<PublicConfiguration<any, any, BareFetcher<any>>> {}
export type SenskleExtendNextPageProps = {
  layout?: LayoutTypes;
  swrConfig?: SensklePageSwrConfig;
};
export type SensklePage<P = Record<string, unknown>> = NextPage<P> & SenskleExtendNextPageProps;

/*
|--------------------------------------------------------------------------
| Factory
|--------------------------------------------------------------------------
*/
export const SensklePageFactory = <T extends SenskleExtendNextPageProps,>(Page: React.FC<T>) => {
  const page: SensklePage<T> = props => {
    return <Page {...props} data-sentry-element="Page" data-sentry-component="page" data-sentry-source-file="SensklePage.tsx" />;
  };
  return page;
};