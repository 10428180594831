/*
| Developed by Dirupt
| Filename : axios-error-handler.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Translate } from 'next-translate'
import { isAxiosError } from 'axios'
import { toast } from 'sonner'
import { FormikHelpers } from 'formik'
import { ErrorCodes } from '@/services/api/common.contract'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type ErrorFromVine = {
	message: string
	rule: string
	field: string
}
export type ErrorsFromVine = {
	errors: Array<ErrorFromVine>
}

/*
|--------------------------------------------------------------------------
| Available
|--------------------------------------------------------------------------
*/
export enum AvailableAxiosErrorStatus {
	TOO_MANY_REQUESTS = 429,
}

/*
|--------------------------------------------------------------------------
| Class
|--------------------------------------------------------------------------
*/
export class AxiosErrorHandler {
	static handle<TError>(t: Translate, error: TError, extendsHandler?: (error: TError) => void) {
		if (isAxiosError(error)) {
			console.error('[SENSKLE API] Axios error:', error.toJSON())

			// Check if response data have a `code` property
			if (error.response?.data?.code && error.response?.data?.code in ErrorCodes) {
				toast.error(t(`common:apiErrors.codes.${error.response.data.code}`))
				return
			}

			// Check if response status is a known status
			if (error.response?.status && error.response.status in AvailableAxiosErrorStatus) {
				toast.error(t(`common:apiErrors.status.${error.response.status}`))
				return
			}

			if (extendsHandler) {
				extendsHandler(error)
				return
			} else {
				toast.error(t('common:apiErrors.unknown'))
				return
			}
		}

		if (extendsHandler) {
			extendsHandler(error)
			return
		} else {
			toast.error(t('common:apiErrors.unknown'))
			return
		}
	}

	static handleFormikError<TError, TFormikValues>(
		t: Translate,
		error: TError,
		FormikHelpers: FormikHelpers<TFormikValues>,
		extendsHandler?: (error: TError) => void,
	) {
		if (isAxiosError(error)) {
			if (error.response?.data?.errors) {
				error.response.data.errors.forEach((error: ErrorFromVine) => {
					FormikHelpers.setFieldError(error.field, error.message)
				})

				return
			}

			this.handle(t, error, extendsHandler)
		}
	}
}
